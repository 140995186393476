.raleway {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}
.raleway-medium {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}
.raleway-light {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
.raleway-semiBold {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.raleway-bold {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}
.raleway-extraBold {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
}
.h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 25px;
  color: #000000;
}
@media only screen and (min-width: 64.063em) {
  .h1 {
    font-size: 45px;
  }
}
.h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 30px;
  color: #000000;
}
.p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 80px 0 0 0;
  font-size: 16px;
  color: #000000;
  transition: background-color 0.2s ease-out;
}
h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 25px;
  color: #000000;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 64.063em) {
  h1 {
    font-size: 45px;
  }
}
h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 30px;
  color: #000000;
}
p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
section,
.section {
  position: relative;
  width: 100%;
  max-width: 800px;
  max-width: 1215px;
  margin: 0 auto;
  padding: 0 30px;
  text-align: center;
}
section img,
.section img {
  max-width: 400px;
  margin-top: 60px;
}
@media only screen and (min-width: 40.063em) {
  section img,
  .section img {
    margin-top: 0;
  }
}
section p,
.section p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
section.personal-data,
.section.personal-data {
  background-color: #CDD953;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
input:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
input:not([type="submit"]),
select {
  background-color: transparent;
  border: 1px solid #000000;
  color: #000000;
  border-radius: none;
  outline: none;
  font-size: 16px;
  padding: 8px 10px;
  max-width: 400px;
}
input:not([type="submit"]):-webkit-autofill,
select:-webkit-autofill,
input:not([type="submit"]):-webkit-autofill:hover,
select:-webkit-autofill:hover,
input:not([type="submit"]):-webkit-autofill:focus,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
select {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
}
input[type="submit"],
button[type="submit"],
.btn--submit {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  cursor: pointer;
  background-color: #F3F4F5;
  color: #000000;
  text-decoration: none;
  padding: 10px 24px;
  border: none;
  display: block;
  margin: 50px auto 0 auto;
  font-size: 16px;
}
input[type="submit"] svg,
button[type="submit"] svg,
.btn--submit svg {
  display: inline-block;
  margin-left: 16px;
}
input[type="submit"] svg,
button[type="submit"] svg,
.btn--submit svg {
  display: inline-block;
  margin-left: 16px;
}
a.btn--submit {
  display: table;
}
label {
  position: relative;
}
.checkbox {
  display: inline-block;
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .checkbox {
    margin: 0 20px;
  }
}
.checkbox input[type="radio"],
.checkbox input[type="checkbox"] {
  display: none;
}
.checkbox input[type="radio"]:checked + .checkbox__label .checkbox__icon::after,
.checkbox input[type="checkbox"]:checked + .checkbox__label .checkbox__icon::after {
  opacity: 1;
}
.checkbox input[type="radio"]:checked + .checkbox__label .checkbox__icon,
.checkbox input[type="checkbox"]:checked + .checkbox__label .checkbox__icon {
  background-color: #000000;
}
.checkbox__label {
  cursor: pointer;
}
.checkbox__label span:not(.vrh-tooltip):not(.vrh-tooltip__content) {
  position: relative;
  display: block;
  border: 2px solid #000000;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin: 0 auto 13px auto;
}
.checkbox__label span:not(.vrh-tooltip):not(.vrh-tooltip__content):after {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) rotate(45deg);
  /*Add another block-level blank space*/
  content: '';
  display: block;
  /*Make it a small rectangle so the border will create an L-shape*/
  width: 4px;
  height: 10px;
  /*Add a white border on the bottom and left, creating that 'L' */
  border: solid #000;
  border-width: 0 2px 2px 0;
  /*Rotate the L 45 degrees to turn it into a checkmark*/
}
.checkbox__label.align-left {
  display: grid;
  -ms-grid-columns: 70px auto;
  grid-template-columns: 70px auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
  text-align: left;
}
.checkbox__label.align-left span:not(.vrh-tooltip):not(.vrh-tooltip__content) {
  margin: 0;
  -ms-grid-column-align: normal;
  justify-self: normal;
}
@media only screen and (min-width: 40.063em) {
  .checkbox__label.align-left span:not(.vrh-tooltip):not(.vrh-tooltip__content) {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
  }
}
.checkbox .vrh-tooltip {
  position: absolute;
  bottom: 25px;
  right: -20px;
}
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 10px;
  height: 10px;
  padding: 0;
  border: 1px solid #000000;
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-top;
  margin-right: 10px;
}
input[type="radio"]:checked {
  background-color: #000000;
}
input[type="date"] {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  width: auto !important;
  margin-left: 10px;
}
input[type="date"]input[type="date"]:-webkit-clear-button,
input[type="date"]input[type="date"]::-webkit-inner-spin-button {
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background: url('/verkehrsrecht/assets/images/icons/calendar.svg') no-repeat;
  padding: 0;
  width: 20px;
  height: 20px;
}
textarea {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  padding: 10px;
  border: 1px solid #000000;
  background-color: transparent;
  width: 90%;
  max-width: 589px;
  min-height: 146px;
  resize: none;
  outline: none;
}
textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea:-moz-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  /*div {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;

        * {
            &:nth-child(1):not(.checkbox) {
                //margin-right: 2.5px;
            }

            &:nth-child(2):not(.checkbox) {
                margin-left: 2.5px;
            }
        }
    }*/
}
form .checkbox {
  margin: 20px;
}
form input:not([type="submit"]) {
  width: 100%;
  margin-bottom: 15px;
}
.vrh-btn,
.btn {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  cursor: pointer;
  background-color: #F3F4F5;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 24px;
  border: none;
}
.vrh-btn svg,
.btn svg {
  display: inline-block;
  margin-left: 16px;
}
.col-2 {
  width: 90%;
  max-width: 589px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 15px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin: 0 auto 30px auto;
}
/*header*/
.vrh-main-navigation {
  transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease;
  -ms-transition: top 0.3s ease;
  -moz-transition: top 0.3s ease;
  -o-transition: top 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 20px;
  z-index: 100;
}
.vrh-main-navigation.is--hidden {
  top: -80px;
}
@media only screen and (min-width: 40.063em) {
  .vrh-main-navigation.is--hidden {
    top: 0;
  }
}
.vrh-main-navigation .vrh-logo {
  position: relative;
  z-index: 99;
}
.vrh-main-navigation input[type="checkbox"] {
  display: none;
}
.vrh-main-navigation input[type="checkbox"]:checked ~ label span:first-child {
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  top: 50%;
}
.vrh-main-navigation input[type="checkbox"]:checked ~ label span:last-child {
  transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  -moz-transform: rotate(-40deg);
  -o-transform: rotate(-40deg);
  top: 50%;
}
.vrh-main-navigation input[type="checkbox"]:checked ~ label ~ nav {
  left: 0;
}
.vrh-main-navigation label {
  position: absolute;
  z-index: 99;
  top: 20px;
  right: 20px;
  display: block;
  border: 1px solid #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 12px;
}
@media only screen and (min-width: 64.063em) {
  .vrh-main-navigation label {
    display: none;
  }
}
.vrh-main-navigation label span {
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  position: absolute;
  left: 10px;
  display: block;
  width: 19px;
  height: 1px;
  background-color: #000000;
}
.vrh-main-navigation label span:first-child {
  top: calc(50% - 4px);
}
.vrh-main-navigation label span:last-child {
  top: calc(50% + 4px);
}
.vrh-main-navigation nav {
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  position: absolute;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background-color: #F2D3C1;
  z-index: 98;
}
@media only screen and (min-width: 64.063em) {
  .vrh-main-navigation nav {
    position: relative;
    width: auto;
    height: auto;
    top: auto;
    left: auto;
    text-align: right;
    background-color: transparent;
  }
}
.vrh-main-navigation nav p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #373739;
}
@media only screen and (min-width: 64.063em) {
  .vrh-main-navigation nav p {
    display: none;
  }
}
.vrh-main-navigation nav p a {
  text-decoration: none;
  color: #373739;
}
.vrh-main-navigation nav p a:nth-child(1)::after {
  content: "|";
  display: inline-block;
  color: #373739;
  margin: 0 8px;
}
.vrh-main-navigation nav p a:nth-child(2):after {
  content: "";
  display: block;
  margin-bottom: 10px;
}
.vrh-main-navigation ul {
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
@media only screen and (min-width: 64.063em) {
  .vrh-main-navigation ul {
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    position: relative;
    left: auto;
    top: auto;
    text-align: right;
  }
}
.vrh-main-navigation ul li {
  display: block;
  margin-bottom: 30px;
}
@media only screen and (min-width: 40.063em) {
  .vrh-main-navigation ul li {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 64.063em) {
  .vrh-main-navigation ul li {
    display: inline-block;
    margin: 0 23px;
  }
}
.vrh-main-navigation ul li a {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 30px;
  color: var(--black);
  text-decoration: none;
}
@media only screen and (min-width: 40.063em) {
  .vrh-main-navigation ul li a {
    font-size: 40px;
  }
}
@media only screen and (min-width: 64.063em) {
  .vrh-main-navigation ul li a {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
}
h1 small {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  display: block;
  font-size: 18px;
  margin-bottom: 15px;
}
/*footer*/
.vrh-footer-navigation {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  width: 90%;
  margin: 52px auto 0 auto;
  padding: 15px 0;
  border-top: 0.5px solid rgba(55, 55, 57, 0.6);
  color: rgba(55, 55, 57, 0.6);
}
@media only screen and (min-width: 40.063em) {
  .vrh-footer-navigation {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
  }
}
.vrh-footer-navigation div {
  text-align: center;
  color: rgba(55, 55, 57, 0.6);
}
@media only screen and (min-width: 40.063em) {
  .vrh-footer-navigation div {
    text-align: left;
  }
}
.vrh-footer-navigation div:nth-child(1) {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}
@media only screen and (min-width: 40.063em) {
  .vrh-footer-navigation div:nth-child(1) {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}
.vrh-footer-navigation div:nth-child(2) {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  margin-bottom: 10px;
}
@media only screen and (min-width: 40.063em) {
  .vrh-footer-navigation div:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
    text-align: right;
    margin-bottom: 0;
  }
}
.vrh-footer-navigation p {
  color: var(--grey);
  margin: 0;
}
.vrh-footer-navigation a {
  color: var(--grey);
  text-decoration: none;
}
.vrh-footer-navigation ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.vrh-footer-navigation ul li {
  display: inline-block;
  margin: 0 ;
}
.vrh-footer-navigation ul li:not(:last-child):after {
  content: "|";
  margin: 0 5px;
  color: var(--grey);
}
/*section*/
.vrh-home {
  padding: 20px;
  text-align: center;
  max-width: 850px;
}
.vrh-home img {
  margin-bottom: 60px;
}
.vrh-home h1 {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 31px;
}
@media only screen and (min-width: 64.063em) {
  .vrh-home h1 {
    font-size: 35px;
  }
}
.vrh-home p {
  margin-bottom: 32px;
}
.vrh-home p b {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}
.vrh-home .vrh-btn {
  display: table;
  margin: 40px auto 0 auto;
}
.ribbon {
  display: block;
  height: 26px;
  line-height: 26px;
  width: 67.5px;
  font-size: 12px;
  background: #F3F4F5;
  color: #A890F6;
  position: absolute;
  right: 4px;
  top: 46%;
  transform: translateY(-50%);
}
.ribbon--black {
  color: #000000;
}
@media only screen and (min-width: 40.063em) {
  .ribbon {
    right: -103px;
    top: 50%;
  }
}
.ribbon:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 12.7px solid transparent;
  border-right: 12.5px solid #F3F4F5;
  border-bottom: 0px solid transparent;
  position: absolute;
  left: -12.5px;
}
@media only screen and (min-width: 40.063em) {
  .ribbon:before {
    border-top: 13.7px solid transparent;
  }
}
.ribbon:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 12.5px solid #F3F4F5;
  border-bottom: 13.7px solid transparent;
  position: absolute;
  left: -12.5px;
  bottom: 0;
}
.car-types {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.33fr 0.33fr 0.33fr;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
  margin: 50px auto;
}
.car-types div {
  margin: 0 0 25px 0;
}
@media only screen and (min-width: 40.063em) {
  .car-types {
    -ms-grid-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
  }
}
/*div*/
.accident-actions {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.33fr 0.33fr 0.33fr;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
}
.accident-actions__form {
  max-width: none;
}
/*div*/
.accident-locations {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.33fr 0.33fr 0.33fr;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
}
.accident-locations__form {
  max-width: none;
}
input[type="file"] {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  border: 1px solid #000000;
  padding: 50px 90px;
  width: 95%;
  max-width: 668px;
}
/*section*/
.file-upload form {
  margin: 40px auto 50px auto;
  max-width: none;
}
.file-upload .checkbox {
  position: relative;
  max-width: 700px;
}
.file-upload .checkbox__label {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 25px auto;
  text-align: left;
  -ms-grid-columns: 45px calc(100% - 45px);
  grid-template-columns: 45px calc(100% - 45px);
}
.file-upload .checkbox div {
  display: block;
}
.file-upload p small {
  display: block;
}
.file-upload #agreePrivacy,
.file-upload #agreeMail {
  display: block;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 12px;
}
/***************************************
**************** TEAM ******************
***************************************/
/*sectio*/
.djh-team {
  text-align: left;
  max-width: none;
  /*a*/
}
@media only screen and (min-width: 64.063em) {
  .djh-team {
    padding-top: 87px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 40.063em) {
  .djh-team__grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .djh-team__grid div:nth-child(1) {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .djh-team__grid div:nth-child(2) {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    text-align: right;
    padding-right: 80px;
    max-width: 650px;
    -ms-grid-column-align: right;
    justify-self: right;
  }
}
@media only screen and (min-width: 64.063em) {
  .djh-team__grid div:nth-child(2) {
    padding-right: 150px;
  }
}
.djh-team__intro-img {
  margin: 20px -30px 50px 0;
  width: calc(100% + 30px);
  max-width: none;
}
.djh-team__contact-btn {
  display: inline-block;
  margin: 60px 0 95px 0;
}
.djh-team ul {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  max-width: 1215px;
}
@media only screen and (min-width: 40.063em) {
  .djh-team ul {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 80px;
  }
}
.djh-team ul li {
  text-align: center;
  margin: 4px 0 40px 0;
  max-width: 150px;
}
.djh-team ul li img {
  width: 100%;
  border-radius: 50%;
}
.djh-team ul li:nth-child(odd) {
  -ms-grid-column-align: start;
  justify-self: start;
}
@media only screen and (min-width: 40.063em) {
  .djh-team ul li:nth-child(odd) {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}
.djh-team ul li:nth-child(even) {
  -ms-grid-column-align: end;
  justify-self: end;
}
@media only screen and (min-width: 40.063em) {
  .djh-team ul li:nth-child(even) {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}
.djh-team ul li p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 13px;
}
.djh-team ul li p b {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  display: block;
  color: #373739;
  font-size: 18px;
  margin-bottom: 12px;
}
.djh-team__start-btn {
  display: table;
  margin: 20px auto;
}
@media only screen and (min-width: 40.063em) {
  .djh-team__start-btn {
    margin: 40px auto 80px auto;
  }
}
/***************************************
**************** FAQ *******************
***************************************/
/*section*/
.vrh-faq {
  text-align: left;
  min-height: 65vh;
  padding: 0px 20px 0 20px;
}
.vrh-faq h1 {
  color: #000000;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}
.vrh-faq ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  max-width: 880px;
}
@media only screen and (min-width: 64.063em) {
  .vrh-faq ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.vrh-faq ul li {
  padding: 15px;
  border: 1px solid #000000;
  margin-bottom: 20px;
  -ms-grid-column-align: center;
  justify-self: center;
}
@media only screen and (min-width: 40.063em) {
  .vrh-faq ul li {
    max-width: calc(100% - 10px);
    width: 100%;
  }
  .vrh-faq ul li:nth-child(odd) {
    -ms-grid-column-align: start;
    justify-self: start;
  }
  .vrh-faq ul li:nth-child(even) {
    -ms-grid-column-align: end;
    justify-self: end;
  }
}
.vrh-faq ul li input[type="checkbox"] {
  display: none;
}
.vrh-faq ul li input[type="checkbox"]:checked ~ label svg {
  transform: translateY(-50%) rotate(180deg);
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
}
.vrh-faq ul li input[type="checkbox"]:checked ~ label svg g {
  fill: #000000;
  stroke: #000000;
}
.vrh-faq ul li input[type="checkbox"]:checked ~ label svg g polyline {
  stroke: #F3F4F5;
}
.vrh-faq ul li input[type="checkbox"]:checked ~ label ~ div {
  height: auto;
  margin-top: 20px;
}
.vrh-faq ul li label {
  font-weight: 400;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  cursor: pointer;
  color: #000000;
  display: block;
  padding-right: 30px;
  position: relative;
}
.vrh-faq ul li label svg {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  position: absolute;
  top: 50%;
  right: 0;
  stroke: #000000;
}
.vrh-faq ul li label svg g {
  stroke: #000000;
}
.vrh-faq ul li div {
  font-weight: 400;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  color: #000000;
  height: 0;
  overflow: hidden;
}
/***************************************
**************** Contact ***************
***************************************/
/*section*/
.vrh-contact {
  text-align: left;
  max-width: 1215px;
  margin: 80px auto 0;
  padding: 0 30px;
}
@media only screen and (min-width: 64.063em) {
  .vrh-contact {
    padding: 0;
  }
}
.vrh-contact img {
  max-width: none;
  margin-left: -30px;
  width: calc(100% + 60px);
}
.vrh-contact__form {
  margin-top: 50px;
}
@media only screen and (min-width: 40.063em) {
  .vrh-contact__form {
    margin-top: 40px;
    margin-right: 0;
  }
}
.vrh-contact__form label {
  display: block;
  width: 100%;
  margin: 10px auto;
}
.vrh-contact__form label input {
  width: 100%;
  max-width: none;
}
.vrh-contact__form textarea {
  width: 100%;
  margin-bottom: 20px;
}
.vrh-contact button[type="submit"] {
  display: inline-block;
  margin: 50px 0 0 0;
}
.vrh-contact textarea {
  width: 100%;
}
@media only screen and (min-width: 40.063em) {
  .djh-finish h1 br {
    display: none;
  }
}
.djh-finish p {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}
.back-btn {
  position: absolute;
  bottom: 5px;
  left: 30px;
  color: #000000;
  text-decoration: none;
  font-size: 0;
  z-index: 99;
}
@media only screen and (min-width: 40.063em) {
  .back-btn {
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    top: 50%;
    font-size: 13px;
    bottom: auto;
  }
}
@media only screen and (min-width: 64.063em) {
  .back-btn {
    left: 0;
  }
}
.back-btn svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 19px;
}
.vrh-imprint {
  text-align: left;
  padding-top: 80px;
}
.vrh-imprint h1,
.vrh-imprint p {
  margin-left: 0;
}
.vrh-imprint b {
  display: block;
}
.vrh-imprint a {
  color: #000000;
}
.vrh-tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  z-index: 15;
}
.vrh-tooltip:hover .vrh-tooltip__content {
  opacity: 1;
}
.vrh-tooltip svg {
  width: 100%;
  height: 100%;
  stroke: #F3F4F5;
}
.vrh-tooltip svg circle {
  stroke: #A890F6;
  fill: #000000;
}
.vrh-tooltip.stepOne svg circle {
  stroke: #A9E6CE;
}
.vrh-tooltip.stepTwo svg circle {
  stroke: #ADDDEB;
}
.vrh-tooltip.stepThree svg circle {
  stroke: #ADDDEB;
}
.vrh-tooltip.stepFour svg circle {
  stroke: #ADDDEB;
}
.vrh-tooltip.stepFive svg circle {
  stroke: #ADDDEB;
}
.vrh-tooltip.stepSix svg circle {
  stroke: #ADDDEB;
}
.vrh-tooltip.stepSeven svg circle {
  stroke: #ADDDEB;
}
.vrh-tooltip__content {
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  opacity: 0;
  position: absolute;
  top: -100%;
  left: -220px;
  background-color: #000000;
  padding: 20px;
  color: #F3F4F5;
  text-align: left;
  font-size: 12px;
  width: 230px;
  z-index: -1;
}
@media only screen and (min-width: 40.063em) {
  .vrh-tooltip__content {
    right: -190px;
    width: 200px;
    left: auto;
  }
}
.vhr-back-btn {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  position: absolute;
  left: 10px;
  top: 35.4%;
  color: #000000;
  text-decoration: none;
  z-index: 9;
  background-color: transparent;
  border: none;
}
@media only screen and (min-width: 40.063em) {
  .vhr-back-btn {
    top: 50%;
    left: 10px;
  }
}
.vhr-back-btn svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.vhr-back-btn span {
  display: none;
}
@media only screen and (min-width: 40.063em) {
  .vhr-back-btn span {
    display: inline-block;
  }
}
input[name="accident-date"] {
  width: 66% !important;
}
@media only screen and (min-width: 40.063em) {
  input[name="accident-date"] {
    width: 70% !important;
  }
}
/*
#usercentrics-button #uc-banner-modal .uc-banner-content {
    background-color: @green !important;
}

#usercentrics-button #uc-banner-modal .uc-optin-description {
    color: @black !important;
}

#usercentrics-button #uc-banner-modal .btn-list {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 50px;
    
    .uc-btn {
        margin: 0 10px !important;
        width: 100% !important;
        
        &#uc-btn-deny-banner, &#uc-btn-accept-banner, &#uc-btn-more-info-banner {
            width: 100% !important;
        }
    }
}

#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-accept-banner, #usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-more-info-banner, #usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner {
    width: 100% !important;
}

.usercentrics-button .uc-main-banner .btn-deny {
    background-color: transparent !important;
    border: 1px solid @white !important;
}

.usercentrics-button.layout-1 .uc-banner-content .btn-list .uc-btn.uc-btn-primary {
    background-color: @white !important;
}
*/
fieldset {
  border: none;
  margin-top: 20px;
}
#finish-submit {
  max-width: 800px;
}
.vhr-finish-overview {
  display: block;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
}
@media only screen and (min-width: 40.063em) {
  .vhr-finish-overview {
    margin: 0 auto;
  }
}
.vhr-finish-overview h3 {
  margin: 20px 0;
}
.vhr-finish-overview p {
  display: block;
  margin: 0 !important;
  width: 100%;
}
.vhr-finish-overview ul {
  list-style-type: none;
  padding: 0;
}
.vhr-finish-overview ul li {
  margin: 0 !important;
}
.vhr-finish-overview div {
  display: block;
  margin: 0 !important;
}
.vhr-finish-overview div .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}
.vhr-finish-overview div .grid dd {
  padding-left: 20px;
}
@media only screen and (min-width: 40.063em) {
  .vhr-finish-overview div .grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  max-width: 400px;
  margin: 0 auto;
}
.grid-2 .checkbox {
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}
.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 400px;
  margin: 0 auto;
}
.child-wrapper {
  grid-template-columns: 150px 1fr 50px;
}
.child-wrapper label {
  text-align: left;
}
.child-wrapper label input {
  margin: 0 !important;
  width: 100% !important;
  height: 37px;
}
.child-wrapper button {
  height: 37px;
  width: 37px;
  padding: 0;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}
dl {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}
dl dt,
dl dd {
  margin-bottom: 5px;
}
dl dt {
  font-weight: bold;
}
dl dd {
  margin: 0 !important;
}
/*label*/
.vrh-gender-modal {
  cursor: pointer;
}
.vrh-gender-modal input[type="checkbox"] {
  display: none;
}
.vrh-gender-modal input[type="checkbox"]:checked ~ .wrapper {
  display: block;
}
.vrh-gender-modal input[type="checkbox"]:checked ~ .wrapper span {
  display: block;
}
.vrh-gender-modal .wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.vrh-gender-modal .wrapper span:not(.close) {
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: none;
  position: fixed;
  top: 45%;
  left: 50%;
  z-index: 10;
  background-color: #F3F4F5;
  color: #000000;
  width: 95%;
  max-width: 500px;
  padding: 40px 30px;
  text-align: center;
}
.vrh-gender-modal .wrapper span:not(.close) .close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.vhr-divorce-calculator {
  width: 90%;
  max-width: 400px;
  margin: 0 auto 80px auto;
}
.vhr-divorce-calculator sup {
  font-size: 16px;
}
.vhr-divorce-calculator .grid-2 .checkbox {
  margin-left: 0;
  margin-right: 0;
  max-width: 120px;
}
.vhr-divorce-calculator .grid-2 .checkbox__label {
  justify-content: flex-start;
}
.vhr-divorce-calculator .grid-2.pension-adjustment input[type="radio"] {
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  margin: 5px 0 0 15px;
}
.vhr-divorce-calculator select {
  width: 100%;
}
.vhr-divorce-calculator .grid-3 {
  grid-template-columns: auto auto auto;
  margin-bottom: 20px;
}
.vhr-divorce-calculator .grid-3 p {
  margin: 0;
}
.vhr-divorce-calculator .wrapper {
  position: relative;
}
.vhr-divorce-calculator .vrh-tooltip svg {
  stroke: #81abf4;
}
.vhr-divorce-calculator .vrh-tooltip svg circle {
  stroke: #81abf4;
}
.vhr-divorce-calculator .vrh-tooltip.step-two {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 40.063em) {
  .vhr-divorce-calculator .vrh-tooltip.step-two {
    top: -10px;
    right: -20px;
  }
}
.vhr-divorce-calculator .vrh-tooltip__content {
  display: none;
}
.vhr-divorce-calculator .vrh-tooltip:hover .vrh-tooltip__content {
  display: block;
}
.vhr-divorce-calculator input[type="radio"] {
  transform: translateY(-75%);
  -webkit-transform: translateY(-75%);
  -ms-transform: translateY(-75%);
  -moz-transform: translateY(-75%);
  -o-transform: translateY(-75%);
  position: relative;
  top: 50%;
}
.vhr-divorce-calculator input[type="number"] {
  width: 100%;
  max-width: none !important;
}
.vhr-divorce-calculator div,
.vhr-divorce-calculator dl {
  margin: 20px auto;
}
.vhr-divorce-calculator dl {
  grid-template-columns: auto 1fr;
  margin-bottom: 0;
  align-items: end;
}
.vhr-divorce-calculator dl:nth-of-type(2) dt,
.vhr-divorce-calculator dl:nth-of-type(2) dd {
  height: 27px;
}
.vhr-divorce-calculator dl dt,
.vhr-divorce-calculator dl dd {
  margin-bottom: 4px !important;
}
.vhr-divorce-calculator dl:first-of-type {
  margin-top: 30px;
}
.vhr-divorce-calculator dl:first-of-type dt:first-of-type,
.vhr-divorce-calculator dl:first-of-type dd:first-of-type {
  font-weight: 800;
  border-bottom: 3px solid #000000;
  margin-bottom: 8px !important;
  padding-bottom: 5px !important;
}
.vhr-divorce-calculator dl:first-of-type dt:nth-of-type(2),
.vhr-divorce-calculator dl:first-of-type dd:nth-of-type(2) {
  border-bottom: 1px solid #000000;
  margin-bottom: 5px !important;
  padding-bottom: 5px !important;
}
.vhr-divorce-calculator dl:nth-of-type(2) dt,
.vhr-divorce-calculator dl:nth-of-type(2) dd {
  border-top: 1px solid #000000;
  margin-bottom: 5px !important;
  padding-top: 5px !important;
}
.vhr-divorce-calculator dl dt,
.vhr-divorce-calculator dl dd {
  font-weight: 300;
}
.vhr-divorce-calculator dl dt {
  text-align: left;
}
.vhr-divorce-calculator dl dd {
  text-align: right;
}
.vhr-divorce-calculator ul {
  list-style-type: none;
  text-align: left;
  font-weight: 300;
  padding-left: 15px;
  margin: 0px 0 15px 0;
  color: rgba(55, 55, 57, 0.6);
}
.vhr-divorce-calculator input[type="checkbox"]:checked ~ .wrapper .checkbox__icon {
  background-color: #000000;
}
.vhr-divorce-calculator .checkbox__label {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
.vhr-divorce-calculator .checkbox__label .checkbox__icon {
  width: 10px !important;
  height: 10px !important;
  padding: 0;
  margin: 0 10px 0 0!important;
  border: 1px solid #000000;
  border-radius: 50%;
}
.vhr-divorce-calculator .checkbox__label .checkbox__icon::after {
  display: none !important;
}
.vhr-divorce-calculator input[type="checkbox"]:checked ~ .checkbox__label .checkbox__icon {
  background-color: #000000;
}
.vhr-divorce-calculator input:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.vhr-divorce-calculator button {
  cursor: pointer;
  margin-top: 10px;
}
.vhr-divorce-calculator button svg {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 5px;
}
.vrh-calculator h1 {
  margin-top: 0;
}
